import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form, useLoaderData, useSubmit } from "react-router-dom";
import { analytics } from "shared/lib/analytics";
import { InputNumber, Typography } from "shared/ui";
import { OrdersListRenderer } from "widgets/orders-list";

import { ordersPageLoader } from "./loader";

export const PageOrders = () => {
  const { orders, orderNumber } = useLoaderData() as Awaited<
    ReturnType<typeof ordersPageLoader>
  >;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>();

  const submit = useSubmit();
  const debouncedSubmit = debounce(submit, 800);

  useEffect(() => {
    if (orderNumber && inputRef.current) {
      inputRef.current.value = orderNumber;
    }
  }, [orderNumber]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSubmit(e.currentTarget.form);
  };

  return (
    <React.Fragment>
      <Typography mb={3} variant="headlineH3">
        {t("orders.title")}
      </Typography>
      <Form>
        <InputNumber
          id="orderNumber"
          name="orderNumber"
          onChange={handleChange}
          onFocus={() => analytics.logAction("FocusOrderSearch")}
          placeholder={t("orders.search_input.placeholder")}
          size="small"
          sx={{
            mb: 3,
          }}
          defaultValue={orderNumber}
          allowLeadingZeroes
        />
      </Form>
      <OrdersListRenderer
        orders={orders.items}
        searchOrderNumber={orderNumber}
      />
    </React.Fragment>
  );
};
