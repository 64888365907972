import { useSendOrderLink } from "features/send-order-link";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { analytics } from "shared/lib/analytics";
import { useSnackbar } from "shared/lib/common";
import {
  Box,
  Button,
  IconMail,
  Input,
  Show,
  Stack,
  Typography,
} from "shared/ui";
import { InfoBlock } from "shared/ui/info-block";

export type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  orderId: string;
  paymentLinkUrl?: string;
};

export type FieldValues = {
  email?: string;
};

export const Form = ({ onSuccess, orderId, paymentLinkUrl }: Props) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const sendOrderLink = useSendOrderLink();

  const {
    formState,
    handleSubmit: createSubmitHandler,
    register,
    reset,
    setError,
  } = useForm<FieldValues>({
    defaultValues: {
      email: "",
    },
  });

  const handleLinkCopy = () => {
    analytics.logAction("ClickCopyPaymentLink", { order_id: orderId });

    enqueueSnackbar({
      variant: "success",
      message: t("orders.send_qr_modal.copy_link_notification"),
    });
  };

  const handleSubmit: SubmitHandler<FieldValues> = (data) => {
    analytics.logAction("ClickSendPaymentLinkByEmail", { order_id: orderId });
    sendOrderLink.mutate(
      {
        email: data.email || undefined,
        orderId,
      },
      {
        onError: (error) => {
          // @ts-ignore
          const emailError = error?.response?.data?.error?.errors?.email?.[0];

          if (emailError) {
            setError("email", {
              message: t("orders.send_qr_modal.email_input.error"),
            });
          }
        },
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: t("orders.send_qr_modal.notification.success", {
              email: data.email,
            }),
          });
          reset();
          onSuccess();
        },
      },
    );
  };

  const isDirty = formState.isDirty;
  return (
    <Box component="form" onSubmit={createSubmitHandler(handleSubmit)}>
      <Box mb={2}>
        <InfoBlock
          title={t("orders.send_qr_modal.payment_link_label")}
          description={paymentLinkUrl || ""}
          canCopy
          onCopy={handleLinkCopy}
        />
        <Typography mt={2.5} variant="regularTextRegular" color="neutral.50">
          {t("orders.send_qr_modal.email_input.label")}
        </Typography>
        <Input
          color="gray"
          error={Boolean(formState.errors.email)}
          InputProps={{
            startAdornment: <IconMail />,
          }}
          helperText={formState.errors.email?.message}
          placeholder={t("orders.send_qr_modal.email_input.placeholder")}
          sx={{
            mt: 1.5,
          }}
          size="small"
          {...register("email")}
        />
        <Show when={formState.errors.root?.multiple?.message}>
          {(value) => (
            <Typography color="error" mt={1} variant="regularTextRegular">
              {value}
            </Typography>
          )}
        </Show>
      </Box>

      <Stack spacing={1}>
        <Button
          disabled={!isDirty}
          fullWidth={true}
          loading={sendOrderLink.isPending}
          type="submit"
        >
          {t("orders.send_qr_modal.send_button_text")}
        </Button>
      </Stack>
    </Box>
  );
};
