import { useSnackbar } from "@mychili/ui-web";
import { useState } from "react";
import { useParams, useRevalidator } from "react-router-dom";
import { analytics } from "shared/lib/analytics";
import {
  Dialog,
  EventButton as Button,
  IconWarning,
  InfoCard,
} from "shared/ui";

import { useRefundOrder } from "../model";

export const RefundOrderButton = () => {
  const [isOpen, setOpen] = useState(false);
  const { id: orderId } = useParams();
  const revalidator = useRevalidator();
  const refundOrder = useRefundOrder();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    analytics.logAction("ClickRefundOrderCancel", { order_id: orderId });
    setOpen(false);
  };

  const handleRefundOrder = () => {
    analytics.logAction("ClickRefundOrderConfirm", { order_id: orderId });

    if (orderId) {
      refundOrder.mutate(
        { orderId },
        {
          onSuccess: () => {
            enqueueSnackbar({
              variant: "success",
              message: "The order has been refunded",
            });

            revalidator.revalidate();
            handleClose();
          },
          onError: () => {
            enqueueSnackbar({
              variant: "error",
              message: "Error refunding order",
            });
          },
        },
      );
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <InfoCard
          iconVariant="warning"
          title="Refund for the order"
          description="Refund the money for the order to the customer? This action is not reversible" // @TODO: set bold for second sentence.
          primaryButtonLabel="Yes, refund"
          secondaryButtonLabel="No, close"
          onPrimaryAction={handleRefundOrder}
          onSecondaryAction={handleClose}
          onClose={handleClose}
        />
      </Dialog>
      <Button
        fullWidth
        startIcon={<IconWarning />}
        onClick={handleOpen}
        withEvent={() =>
          analytics.logAction("ClickRefundOrder", { order_id: orderId })
        }
      >
        Refund
      </Button>
    </>
  );
};
