import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { analytics } from "shared/lib/analytics";

export const PageProtected = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.logViewPage(location.pathname.substring(1));
  }, [location.pathname]);

  return <Outlet />;
};
