import "./styles";

import * as Amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_API_KEY } from "shared/config";
import { Sentry } from "shared/lib/sentry";

import {
  QueryClientProvider,
  RouterProvider,
  SessionProvider,
  SnackbarProvider,
  ThemeProvider,
} from "./providers";

Sentry.init();

Amplitude.init(AMPLITUDE_API_KEY);

export const App = () => {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <SessionProvider>
            <RouterProvider />
          </SessionProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
