import dayjs from "dayjs";
import { getOrderPublicStatus } from "entities/orders";
import { RefundOrderButton } from "features/refund-order";
import { FormSendOrderLink } from "features/send-order-link";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useRevalidator } from "react-router-dom";
import { analytics } from "shared/lib/analytics";
import { getImageSrcFromBase64 } from "shared/lib/common";
import { format as formatCurrency } from "shared/lib/currency";
import { format as formatDate } from "shared/lib/datetime";
import { capitalize, startCase } from "shared/lib/lodash";
import { USER_ROLE, useRoles } from "shared/lib/user";
import {
  ActionSheet,
  Box,
  Card,
  Container,
  Divider,
  EventButton as Button,
  IconShare,
  Label,
  Show,
  Stack,
  Typography,
} from "shared/ui";

import { orderPageLoader } from "./loader";

type RowProps = {
  title: string;
  value: React.ReactNode;
};

const Row = ({ title, value }: RowProps) => {
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Typography color="neutral.20" variant="regularTextRegular">
        {title}
      </Typography>
      {typeof value === "string" ? (
        <Typography color="neutral.20" variant="regularTextRegular">
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  );
};

export const PageOrder = () => {
  const { t } = useTranslation();

  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);

  const { isMemberOf } = useRoles();

  const revalidator = useRevalidator();

  const navigate = useNavigate();

  const { order, outlet, employee } = useLoaderData() as Awaited<
    ReturnType<typeof orderPageLoader>
  >;

  const isRefundAllowed = employee.roles?.includes("refund_accessed_employee");

  const publicStatus = getOrderPublicStatus(order.status);

  const chipColorByPublicStatus = {
    CANCELLED: "error",
    COMPLETED: "success",
    DRAFT: "neutral",
    REFUNDED: "warning",
    REFUND_IN_PROGRESS: "warning",
  } as const;

  const handleSendLinkButtonClick = () => {
    analytics.logAction("ClickSendPaymentLink", { order_id: order?.id });
    setOpenSendEmailDialog(true);
  };

  const handleSendLinkDialogClose = () => {
    setOpenSendEmailDialog(false);
  };

  const handleCheckStatusClick = () => {
    revalidator.revalidate();
  };

  const now = dayjs();
  const updatedAt = dayjs(order.updated);
  const daysFromLastUpdate = now.diff(updatedAt, "days");

  const showRefundButton =
    (isMemberOf(USER_ROLE.ADMIN) || isRefundAllowed) &&
    (order.status === "ACTIVE" || order.status === "REPAID") &&
    daysFromLastUpdate <= 10;

  const showCheckStatusButton = publicStatus === "DRAFT";

  return (
    <React.Fragment>
      <Container
        sx={{
          py: 2,
        }}
      >
        <Show when={publicStatus === "DRAFT" && order.linkQr}>
          {(linkQr) => (
            <Card
              sx={{
                mb: 2,
                p: 2,
              }}
            >
              <Typography align="center" variant="regularTextMedium">
                {t("orders.order.title")} #{order.orderNumber}
              </Typography>
              <Typography
                color="neutral.70"
                mb={2}
                textAlign="center"
                variant="smallTextRegular"
              >
                {t("orders.order.description")}
              </Typography>
              <React.Fragment>
                {/* @TODO From theme */}
                <Box sx={{ background: "#F7F7F8" }} p={2}>
                  <Box
                    component="img"
                    display="block"
                    maxWidth={200}
                    mx="auto"
                    src={getImageSrcFromBase64(linkQr)}
                    textAlign="center"
                    width="100%"
                  />
                </Box>
                <Button
                  fullWidth={true}
                  onClick={handleSendLinkButtonClick}
                  size="medium"
                  startIcon={<IconShare />}
                  variant="link"
                >
                  {t("orders.order.send_qr_button_text")}
                </Button>
              </React.Fragment>
            </Card>
          )}
        </Show>
        <Card
          sx={{
            p: 2,
          }}
        >
          <Typography color="neutral.20" mb={0.5} variant="mediumTextSemiBold">
            {outlet.name}
          </Typography>
          <Typography color="neutral.50" mb={2} variant="smallTextRegular">
            {outlet.locationGeo}
          </Typography>
          <Stack spacing={1}>
            <Row
              title={t("orders.order.details.order_number")}
              value={order.orderNumber}
            />
            <Row
              title={t("orders.order.details.created")}
              value={
                <Typography color="neutral.50" variant="regularTextRegular">
                  {formatDate(order.created, "DD/MM/YYYY, HH:mm")}
                </Typography>
              }
            />
            <Row
              title={t("orders.order.details.status")}
              value={
                <Label
                  color={chipColorByPublicStatus[publicStatus]}
                  size="M"
                  showIndicator
                >
                  {capitalize(startCase(publicStatus))}
                </Label>
              }
            />
          </Stack>
          <Divider
            sx={{
              my: 2,
            }}
          />

          <Row
            title={t("orders.order.details.amount")}
            value={
              <Typography color="neutral.20" variant="bigTextSemiBold">
                {formatCurrency(order.initialAmount ?? order.amount)}
              </Typography>
            }
          />
        </Card>
        <Box
          mt={{
            xs: "76px",
            md: 2,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            bottom={16}
            left={16}
            position={{
              xs: "fixed",
              md: "static",
            }}
            right={16}
            gap={2}
          >
            <Show when={showRefundButton}>
              <RefundOrderButton />
            </Show>
            <Show when={showCheckStatusButton}>
              <Button
                onClick={handleCheckStatusClick}
                withEvent={() => analytics.logAction("ClickCheckPaymentStatus")}
              >
                {t("orders.order.check_status_button_text")}
              </Button>
            </Show>
            <Button
              variant="link"
              fullWidth={true}
              onClick={() => {
                navigate(-1);
              }}
              withEvent={() => analytics.logAction("ClickCloseOrder")}
            >
              {t("common.close")}
            </Button>
          </Box>
        </Box>
      </Container>
      <ActionSheet
        onOpen={() => {}}
        onClose={handleSendLinkDialogClose}
        isOpen={openSendEmailDialog}
        hasCloseButtonOnMobile
        title={t("orders.send_qr_modal.title")}
        isSwipeable={false}
      >
        <FormSendOrderLink
          onCancel={handleSendLinkDialogClose}
          onSuccess={handleSendLinkDialogClose}
          orderId={order.id}
          paymentLinkUrl={order.link}
        />
      </ActionSheet>
    </React.Fragment>
  );
};
